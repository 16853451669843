import React from 'react';
import { Header } from 'components/theme';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/think.svg';
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles';

export const Intro = () => (
  <Wrapper>
    <Header />
    <IntroWrapper as={Container}>
      <Details>
        <h1>Hi There!</h1>
        <h4>I’m Omar and I’m a software engineer!</h4>
        <Button as="a" href="https://gitconnected.com/lehomard/resume" target="_blank" rel="noopener noreferrer">
          Click to see my Resume
        </Button>
      </Details>
      <Thumbnail>
        <img src={dev} alt="I’m Omar and I’m a software engineer!" />
      </Thumbnail>
    </IntroWrapper>
  </Wrapper>
);
