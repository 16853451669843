import React from 'react';
import { Container } from 'components/common';
import dev from 'assets/illustrations/dev.svg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Skills = () => (
  <Wrapper id="about">
    <SkillsWrapper as={Container}>
      <Thumbnail>
        <img src={dev} alt="I’m Omar and I’m a Software engineer!" />
      </Thumbnail>
      <Details>
        <h1>Hi There!</h1>
        <p>
          Hello! I'm Omar, a software engineer based in Paris.
          <br /> I graduated from{' '}
          <a href="https://www.supinfo.com/" rel="noopener noreferrer" target="_blank">
            SUPINFO International University
          </a>{' '}
          in 2016, and then started to work as a Full Stack developer at different companies based in Paris.
          <br /> <br /> Here are a few technologies I've been working with recently:
          <br />
          <br />
          <ul>
            <li>Javacript</li>
            <li>Go</li>
            <li>React</li>
            <li>Gatsby.js</li>
            <li>Next.js</li>
            <li>Docker</li>
          </ul>
        </p>
      </Details>
    </SkillsWrapper>
  </Wrapper>
);
